import React, { useState } from 'react';
import './Admin.css';
import AdminMessages from '../components/AdminMessages';

function Admin() {
  const [activeTab, setActiveTab] = useState('ateliers');
  const [calculations, setCalculations] = useState({});
  const small_max_vol = 50;
  const medium_max_vol = 200;
  const large_max_vol = 800;
  const cost_perstich = 0.33;
  const small_base_cost = 15;
  const medium_base_cost = 25;
  const large_base_cost = 40;
  const special_feature_cost = 5;

  const calculateBasket = () => {
    const width = document.querySelector('input[name="width"]').value;
    const height = document.querySelector('input[name="height"]').value;
    const depth = document.querySelector('input[name="depth"]').value;
    const stitches = document.querySelector('input[name="stitches"]').value;
    const volume = width * height * depth;
    let size_category = '';
    let base_price = 0;
    let stitch_price = 0;
    let special_feature_markup = 0;
    let total_price = 0;
    if (volume <= small_max_vol) {
      size_category = 'small';
      base_price = small_base_cost;
    } else if (volume <= medium_max_vol) {
      size_category = 'medium';
      base_price = medium_base_cost;
    } else {
      size_category = 'large';
      base_price = large_base_cost;
    }
    stitch_price = Math.ceil(stitches * cost_perstich);
    special_feature_markup = Math.ceil(document.querySelector('input[name="special_feature"]').value * special_feature_cost);
    total_price = Math.ceil(base_price + stitch_price+special_feature_markup);
    setCalculations({
      volume,
      size_category,
      base_price,
      stitch_price,
      special_feature_markup,
      total_price: Math.ceil(total_price)
    });
  };



  const renderContent = () => {
    if (activeTab === 'messages')
        return <AdminMessages />;
    else if (activeTab === 'basket-calc')
        return <>
        <h2>Calculateur de paniers</h2>
        <div className="form">
        <p>Utilisez cet outil pour calculer le prix d'un panier</p>
        <label>Largeur: <input type="number" name="width" /></label>
        <label>Hauteur: <input type="number" name="height" /></label>
        <label>Profondeur: <input type="number" name="depth" /></label>
        <label>Points: <input type="number" name="stitches" /></label>
        <label>Option spéciale: <input type="number" name="special_feature" /></label>
        <p>Résultats:</p>
        <p>Volume: {calculations.volume}</p>
        <p>Catégorie: {calculations.size_category}</p>
        <p>Prix de base: {calculations.base_price}</p>
        <p>Prix des points: {calculations.stitch_price}</p>
        <p>Option spéciale: {calculations.special_feature_markup}</p>
        <p>Prix total: {calculations.total_price}</p>
        
    
        <button onClick={() => calculateBasket()}>Calculer</button>
        </div>
      </>
    
  };

  return (
    <div id="admin">
      <h1>BACKROOM</h1>
      <div className="admin-tabs">
        <button className={`${activeTab == 'messages' ? 'active' : '' }`} onClick={() => setActiveTab('messages')}>Messages</button>
        <button className={`${activeTab == 'basket-calc' ? 'active' : '' }`} onClick={() => setActiveTab('basket-calc')}>Calc. Paniers</button>
      </div>
      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
}

export default Admin;
